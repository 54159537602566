@import "../abstracts/_abstracts";
@import "~video-react/styles/scss/video-react";

body {
  color: $primary-color !important;
  font-size: 1.125 rem !important;
}

.navbar-toggler-icon {
  background-image: url("../../../assets/images/Vector.png") !important;
}

#home,
#category,
#Product,
#Cart,
#Orders,
#MyOrder,
#DemoVideo,
#Blogs,
.mainWrapper,
#BlogDetail {
  .slick-prev {
    margin-bottom: -2%;
    z-index: 9;

    &::before {
      content: none;
    }
  }

  .slick-next {
    margin-bottom: -2%;
    z-index: 9;

    &::before {
      content: none;
    }
  }

  #topNav {
    .nav-item {
      z-index: 999;
    }

    .nav-link {
      color: $primary-color !important;
    }

    .bg-gradient {
      color: $white;
      font-size: 0.875rem;
    }

    .navbar-brand.mr-auto {
      display: none;
    }

    .position-fixed {
      a {
        text-decoration: none;
        padding: 0.3rem 0;

        span {
          padding: 0 3px;
          margin-left: 10px;
          line-height: 20%;
        }
      }
    }

    .navbar-toggler-icon {
      height: 14px;
      width: 20px;
      border: inherit;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAYAAAALWoRrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABnSURBVHgB5ZNRDYAwDESvBAFImASkIKE4wAkWkIIFHEzCHMA1FAfHB+El7/Z3adbUcDPSARr2CKen0KVnVHqoJ/0xRgtdofvTORY1pSo8SjfopmzZ9xEs3wIdNcKhPVPvnmYhDW9wAWvtMi1+6ncxAAAAAElFTkSuQmCC") !important;
    }
  }

  #topTabs {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-color: #e5e5e5 !important;

    .slick-track {
      width: auto !important;
    }

    a {
      color: $primary-color !important;
      // font-size: 1.125rem;
    }

    .position-absolute {
      padding: 10px 30px;
      z-index: 9999;
    }

    .nav.nav-tabs {
      .nav-item {
        border: inherit;
      }

      .nav-item.nav-link {
        color: $grey-9b;
        border: inherit;
        border-style: none;
      }

      a.active {
        color: $black-07 !important;
        background-color: inherit;
        border-bottom: 2px solid $black-07 !important;
      }
    }
  }
  .sub-main {
    position: relative;
    .sub-category-wrapper {

      position: relative;
      .sub-category {
        list-style: none;
        padding-inline-start: 0;
        display: none;
        position: absolute;
        top: -05px;
        left: 13.25rem;
        // left: 13.3rem;
        width: 100%;
        min-width: 12rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: white;
        border-radius: 0.25rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        a {
          display: block;
          padding: 0.25rem 1.5rem;
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: #212529;
          &:hover,
          &:focus {
            background-color: #e9ecef;
          }
        }
      }
    }
    &:hover,
    &:focus {
      .sub-category {
        display: block;
      }
    }

    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
      position: absolute;
      right: 0.625rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  #topBanner {
    height: 650px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.34), rgba(0, 0, 0, 0.34)),
      url("../../../assets/images/home/welcome_image.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h1 {
      font-family: Perpetua;
      font-size: 2.5rem;
      margin-bottom: 20px;
    }

    button {
      font-size: 1.25rem;
      font-family: Proxima Nova;
    }
  }

  #recentpostsx {
    // .bg-gradient {
    //   background: linear-gradient(91.1deg, #a58456 -8.1%, #ba7d5c 124.87%);
    // }
    width: 90%;
    margin: 0 auto;
    margin-bottom: 24px;
    .moreBlogs {
      text-align: end;

      a {
        text-underline-offset: 3px;
        color: #212529;
        font-weight: bold;
      }
    }

    .blogtitles {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .blogCard {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 1rem;
      border-radius: 4px;
      border: 1px solid #7c623e45;

      .mainImage {
        // border: 1px solid #7c623e45;
        margin-bottom: 1rem;
        height: 200px;
        // border-radius: 4px 4px 0 0;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .blogContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 10;
        color: #5f5f5f;

        // border-radius: 0 0 4px 4px;

        .calendarDate {
          font-size: 12px;
          font-weight: lighter;
          margin-block-end: 05px;
        }

        h3 {
          font-weight: 600;
          font-size: 16px;
          color: rgba(197, 158, 113, 1);
          line-height: 19px;

          a {
            color: inherit;
          }
        }
        .content-small {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-height: 19px;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 14px;
        }
      }
      .readMain {
        color: #5f5f5f;
        flex-shrink: 0;
        position: relative;
        z-index: 2;
        margin-block-start: 0.8rem;
        transition: transform 0.25s ease;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          left: 0;
          top: 0px;
          height: 1px;
          z-index: 1;
          background-color: #d9d9d9;
        }

        .readMoreBtn {
          color: inherit;
          font-size: 14px;
          width: fit-content;
          border: none;
          cursor: pointer;
          padding-inline: 0;
          padding-block-start: 0.8rem;
          text-align: start;
          position: relative;
          border-radius: 0 0 4px 4px;
          background-color: transparent;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -3px;
            height: 1px;
            z-index: 1;
            background-color: #7c623e;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 0.25s ease;
          }

          &:hover::before {
            transform: scaleX(1);
          }
        }
      }
      &:hover {
        .readMain {
          pointer-events: all;
          visibility: visible;
          transform: translateY(-02px);
        }
      }
    }

    .secondaryBlogs {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: space-between;
      height: 100%;
    }

    .blogCardsec {
      display: flex;
      align-items: flex-end;
      gap: 2rem;
      padding: 1rem;
      border-radius: 4px;
      border: 1px solid #7c623e45;

      .blogContent {
        width: 80%;
        position: relative;
        z-index: 10;
        color: #5f5f5f;
        // border-radius: 0 0 4px 4px;

        .calendarDate {
          font-size: 12px;
          font-weight: lighter;
          margin-block-end: 05px;
        }

        h3 {
          font-weight: 600;
          font-size: 16px;
          color: rgba(197, 158, 113, 1);

          line-height: 19px;
          a {
            color: inherit;
          }
        }
        .content-small {
          line-height: 19px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 14px;
        }
      }
      .readMain {
        color: #5f5f5f;
        flex-shrink: 0;
        position: relative;
        z-index: 2;
        margin-block-start: 0.8rem;
        transition: transform 0.25s ease;

        .readMoreBtn {
          color: inherit;
          font-size: 14px;
          width: fit-content;
          border: none;
          cursor: pointer;
          padding-inline: 0;
          padding-block-start: 0.8rem;
          text-align: start;
          position: relative;
          border-radius: 0 0 4px 4px;
          background-color: transparent;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -3px;
            height: 1px;
            z-index: 1;
            background-color: #7c623e;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 0.25s ease;
          }

          &:hover::before {
            transform: scaleX(1);
          }
        }
      }
      &:hover {
        .readMain {
          pointer-events: all;
          visibility: visible;
          transform: translateY(-02px);
        }
      }
    }
  }

  #testimonials {
    width: 90%;
    margin: 0 auto;
    margin-block-end: 2rem;
    .custom-testimonial-title {
      color: rgba(197, 158, 113, 1);
    }

    .testimonialSlide {
      height: 100%;
    }

    .testimonialWrapper {
      // border: 1px solid c59e71;
      background-color: rgba(255, 252, 248, 1);
      height: 100%;
      border-radius: 4px;
      padding: 2rem;
      position: relative;
      // overflow: hidden;
      // overflow: clip;
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 05px;
        bottom: 0px;
        left: 0;
        right: 0;
        background: linear-gradient(105.52deg, #c59e71 68.5%, #e2b986 78.77%, #f5d2a5 85.91%);
        z-index: 1;
      }

      .testimonialContent {
        position: relative;
        z-index: 2;

        .testimonialText {
          // color: #95613d;
          color: #212529;
          word-spacing: 2px;
          line-height: 25px;
          margin-block-end: 1.5rem;
        }
      }
      small {
        // font-family: "Proxima Nova";
        font-family: holidayfree;
        font-size: 16px;
        letter-spacing: 0.06em;
        line-height: 24px;
        color: rgba(197, 158, 113, 1);
        // font-weight: 700;
        display: block;
        text-align: right;
        margin-inline-start: auto;
      }

      .backgroundSVG {
        position: absolute;
        user-select: none;
        pointer-events: none;
        font-size: 160px;
        color: rgba(255, 244, 231, 1);
        line-height: 1;
        z-index: 1;
        &.top-qoute {
          top: -60px;
          left: 10px;
        }
        &.bottom-qoute {
          bottom: 15px;
          right: 10px;
        }
        svg {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  #detail_1 {
    width: 90%;
    margin: 0 auto;

    h1 {
      font-size: 2rem;
      font-family: Perpetua;
    }

    p {
      // font-size: 1.125rem;
    }

    h5 {
      font-family: Perpetua;
      font-size: 1.25rem;

      span {
        &:first-child {
          border-width: 0 0 1px;
          border-style: solid;
          border-color: #c8c8c8;
        }
      }
    }
  }

  #slider {
    position: relative;
    width: 90%;
    margin: 0 auto;

    .backwardArrow {
      top: 50%;
      position: absolute;
      z-index: 2;
      right: -6px;

      .arrowBg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
      }
    }

    .forwardArrow {
      top: 50%;
      position: absolute;
      z-index: 2;
      left: -6px;

      .arrowBg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
      }
    }

    .sliderCard {
      p {
        &:nth-child(1) {
          color: $grey-92;
        }
      }
    }
  }

  #imgBlocks {
    width: 90%;
    margin: 0 auto;

    .videoThumb {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
      border-radius: 8px;
      width: 100%;
      overflow: hidden;
      position: relative;
      height: 511px;

      .img {
        transform: scaleY(1.2);
        width: 100%;
        transform-origin: 0% 74%;
      }

      .play {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
        left: 0;
      }

      .playIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }

    h1 {
      font-size: 2.25rem;
      font-family: Perpetua;
    }

    button {
      font-size: 1rem;
    }
  }

  #multipleTabs {
    width: 90%;
    margin: 0 auto;

    p {
      &:nth-child(2) {
        color: $grey-92;
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
      }
    }

    .nav.nav-tabs {
      .nav-item {
        border: inherit;
      }

      .nav-item.nav-link {
        color: $grey-9b;
        border: inherit;
        border-style: none;
        font-size: 1.125rem;
      }

      a.active {
        font-weight: 600;
        color: $black-07 !important;
        background-color: inherit;
        border-bottom: 2px solid $black-07 !important;
      }
    }
  }

  #serviceBlock {
    margin: 0 auto;

    h1 {
      font-size: 2.25rem;
      font-family: Perpetua;
    }

    button {
      color: $secondary-color;
      background-color: $lightGrey;
    }

    .row {
      &:nth-child(2) {
        // width: 90%;
        img {
          &:nth-child(1) {
            height: "33px";
            width: "47px";
          }

          &:nth-child(2) {
            height: "32px";
            width: "31px";
          }

          &:nth-child(3) {
            height: "32px";
            width: "31px";
          }

          &:nth-child(4) {
            height: "27px";
            width: "27px";
          }

          &:nth-child(5) {
            height: "21px";
            width: "21px";
          }
        }

        .card_b span {
          line-height: 1.3;
          font-family: Proxima Nova;
          padding-left: 0.8rem !important;
        }
      }
    }
  }

  #pressBlock {
    width: 100%;
    margin: 0 auto;

    .card_c {
      p {
        &:first-child {
          font-size: 1.25rem;
          font-family: Perpetua;
        }
      }
    }

    .p {
      font-size: 1.875rem;
      font-weight: 700;
      font-family: Perpetua;
      line-height: 0.8;
    }

    .slick-prev,
    .slick-next {
      // height: 15px !important;
      // background: #fdfdfd;
      // box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top center;
      background-size: contain;
      padding: 20px;
      border-radius: 20px;
    }

    .review {
      p {
        &:first-child {
          font-size: 1rem;
          font-weight: 600;
        }

        &:nth-child(2) {
          font-size: 0.9rem;
        }

        &:nth-child(3) {
          font-size: 0.875rem;
        }
      }
    }

    h5 {
      // font-size: 1.5rem;
      font-family: Proxima Nova;
    }

    .first {
      div {
        p {
          color: $red-d6;
          font-weight: 600;
          font-family: "Perpetua";
        }
      }
    }
  }

  #footerBlock {
    li,
    span {
      font-size: 0.875rem;
    }

    margin: 0 auto;
    width: 100%;

    div {
      width: 90%;
      margin: 0 auto;
    }

    ul {
      list-style-type: none;
      /* Remove bullets */
      padding: 0;
      /* Remove padding */
      margin: 0;
      /* Remove margins */
    }

    .text-sm-right {
      color: $grey-92;

      span {
        color: $black;
      }
    }
  }

  .whatsAppIcon {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 20;
  }
  .whatsAppIcon img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    #topNav {
      .bg-gradient {
        font-size: 0.65rem;
      }

      .navbar-brand.mr-auto {
        display: block;
      }
    }

    #topTabs {
      display: none;
    }

    #topBanner {
      height: 273px;
      background-image: linear-gradient(rgba(0, 0, 0, 0.34), rgba(0, 0, 0, 0.34)),
        url("../../../assets/images/home/welcome_image.png");

      h1 {
        font-family: Perpetua;
        font-size: 1.5rem;
      }

      button {
        font-size: 1rem;
      }
    }
    // #recentpostsx,
    // #testimonials {
    //   width: 100%;
    //   margin: inherit !important;
    // }
    #detail_1 {
      width: 100%;
      margin: inherit !important;

      h1 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.875rem;
      }

      h5 {
        font-size: 1.5rem;
      }
    }

    .slick-prev {
      left: 0;
    }

    #imgBlocks {
      width: 95%;

      h1 {
        font-size: 1.5rem;
        word-break: keep-all !important;
        word-wrap: normal !important;
      }

      button {
        font-size: 1rem;
      }

      .col-sm-6 {
        img {
          object-fit: fill;
        }
      }
    }

    #multipleTabs {
      width: 95%;

      .nav.nav-tabs {
        .nav-item.nav-link {
          color: $secondary-color !important;
          margin: 0;
          font-size: 0.875rem;

          &.active {
            border-color: $secondary-color !important;
          }
        }
      }

      .nav-link {
        padding: 0.5rem !important;
      }

      .sliderCard {
        height: 215px;

        p {
          &:first-child {
            font-size: 0.875rem;
          }

          &:last-child {
            font-size: 0.8125rem;
          }
        }

        .bg-grey-f5 img {
          height: 118px;
        }
      }
    }
    #recentpostsx,
    #testimonials {
      width: 95%;
    }

    #serviceBlock {
      h1 {
        font-size: 1.5rem;
      }

      button {
        font-size: 0.6919rem;
      }

      .form-control {
        font-size: 0.6919rem;
      }
    }

    #pressBlock {
      width: 90%;
      margin: 0 auto;

      .card_c {
        p {
          &:first-child {
            font-size: 1.25rem;
            font-family: Perpetua;
          }
        }
      }

      .p {
        font-size: 1.875rem;
        font-weight: 700;
        font-family: Perpetua;
      }

      .review {
        p {
          &:first-child {
            font-size: 1rem;
          }

          &:nth-child(2) {
            font-size: 1rem;
          }

          &:nth-child(3) {
            font-size: 0.875rem;
          }
        }
      }

      h5 {
        font-size: 1.5rem;
        font-family: Proxima Nova;
      }

      .col-sm-1 {
        p {
          color: $red-d6;
          font-weight: 600;
          font-family: "Perpetua";
        }
      }
    }

    #footerBlock {
      li,
      p > span {
        font-size: 0.875rem;
      }

      margin: 0 auto;
      width: 100%;

      div {
        width: 100%;
        margin: 0 auto;
      }

      ul {
        list-style-type: none;
        /* Remove bullets */
        padding: 0;
        /* Remove padding */
        margin: 0;
        /* Remove margins */
      }

      .text-right {
        color: $grey-92;

        span {
          color: $black;
        }
      }

      .btn-outline-dark {
        border-color: $yellow-c0 !important;
        color: $yellow-c0;
      }

      .border-d2 .btn {
        height: 56px;
      }
    }
  }

  .home-banner-video {
    height: calc(100vh - 137px);
  }

  @media (orientation: portrait) {
    .home-banner-video {
      display: none;
    }

    .show-m {
      display: none;
    }
  }

  @media (orientation: landscape) {
    .mob-home-banner-video {
      display: none;
    }

    .hide-m {
      display: none;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (max-width: 768px) {
    #testimonials {
      .testimonialSlide {
        height: auto;
        .testimonialWrapper {
          margin-inline: 1.5rem;
        }
      }
    }
    #recentpostsx {
      h4 {
        margin-inline: 5px;
      }
      .rowBlog {
        gap: 1rem;
      }
      .blogCard {
        margin-block-end: 1rem;
        margin-inline: 05px;

        .blogContent {
          display: block;
        }

        .readMain {
          visibility: visible;
          // width: 100%;
          // transform: translateY(-02px);
          pointer-events: all;

          .readMoreBtn {
            &::after {
              width: 90%;
            }

            &::before {
              width: 15%;
            }
          }
        }
      }
      .blogCardsec {
        margin-inline: 05px;
        width: auto;
        flex-direction: column;
        align-items: flex-start;
        gap: 0rem;
        .blogContent {
          width: 100%;
          .content-small {
            -webkit-line-clamp: 2;
          }
        }

        .readMain {
          width: 100%;

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            top: 0px;
            height: 1px;
            z-index: 1;
            background-color: #d9d9d9;
          }
        }
      }
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #topBanner {
      height: 650px;
      background-image: linear-gradient(rgba(0, 0, 0, 0.34), rgba(0, 0, 0, 0.34)),
        url(/static/media/welcome_image.d151f432.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
  }
}
