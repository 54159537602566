@import "../abstracts/_abstracts";

#Cart {
  #enterthecode {
    border: 1px solid $yellow-b4;
  }

  .form-control {
    &:focus {
      border-color: $yellow-b4 !important;
    }
  }

  #checkoutTotal {
    .btn-lg,
    #enterthecode {
      font-size: 1rem !important;
      height: 69.5px;
      font-family: Proxima Nova;
      font-weight: 700;
    }

    p {
      font-size: 1rem;

      &:first-child {
        font-weight: 700;

        span {
          font-size: 0.9175rem;
          color: $grey-a7;
        }
      }

      // &:nth-child(3) {
      //   span {
      //     // font-size: 0.4375rem;
      //     // color: $grey-a7;
      //   }
      // }

      &:nth-child(4) {
        span {
          font-size: 0.9075rem;
          color: $grey-a7;
        }
      }

      &:nth-child(5) {
        span.small {
          font-size: 0.9075rem;
          color: $grey-a7;
        }
      }
    }

    .sliderCard {
      height: 201px;

      p {
        &:first-child {
          font-size: 0.875rem;
        }

        &:last-child {
          font-size: 0.8125rem;
        }
      }

      .bg-grey-f5 img {
        height: 118px;
      }
    }

    .text-secondary-color {
      &::placeholder {
        color: $yellow-b4 !important;
        font-weight: 600;
      }
    }
  }

  .custom-card-cart {
    background-color: $grey-eb;
    border-radius: 6px;

    p {
      color: $grey-9e;
    }

    input,
    .input-group-prepend,
    .input-group-text {
      background-color: #f5f5f5;
    }

    .input-group {
      width: 7.125rem;
    }
  }

  #promoCode {
    .input-group {
      .form-control,
      .input-group-text {
        height: 4rem;
        background-color: $grey-eb !important;
      }
    }
  }

  h3 {
    font-size: 1.875rem;
    font-weight: 500;
  }
}

.hh-grayBox {
  // background-color: #F8F8F8;
  // margin-bottom: 20px;
  padding: 35px;
  // margin-top: 20px;
}

.pt45 {
  padding-top: 45px;
}

.order-tracking {
  text-align: center;
  width: 25%;
  position: relative;
  display: block;
}

.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 0px solid #AFAFAF;
  background-color: $grey-86;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

// .order-tracking .is-complete:after {
//   display: block;
//   position: absolute;
//   content: '';
//   height: 14px;
//   width: 7px;
//   top: -2px;
//   bottom: 0;
//   left: 5px;
//   margin: auto 0;
//   border: 0px solid #AFAFAF;
//   border-width: 0px 2px 2px 0;
//   transform: rotate(45deg);
//   opacity: 0;
// }

.order-tracking.completed .is-complete {
  border-color: $secondary-color;
  border-width: 0px;
  background-color: $secondary-color;
}

.order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}

.order-tracking p {
  color: #A4A4A4;
  font-size: 16px;
  margin-top: -40px;
  margin-bottom: 0;
  line-height: 20px;
}

.order-tracking p span {
  font-size: 14px;
}

.order-tracking.completed p {
  color: #000;
}

.order-tracking::before {
  content: '';
  display: block;
  height: 3px;
  width: calc(100% - 10px);
  background-color: $grey-86;
  top: 10px;
  position: absolute;
  left: calc(-50% + 10px);
  z-index: 0;
}

.order-tracking:first-child:before {
  display: none;
}

.order-tracking.completed:before {
  background-color: $secondary-color;
}

@media only screen and (max-width: 600px) {
  #Orders .nav-item.nav-link {
      padding: 0.2rem .5rem;
      font-size: 0.775rem !important;
    }
}