@font-face {
  font-family: Avenir;
  src: url("../../fonts/Gotham-Font/AvenirLTStd-Black.otf");
  font-display: swap;
}

@font-face {
  font-family: Avenir;
  src: url("../../fonts/Gotham-Font/AvenirLTStd-Book.otf");
  font-display: swap;
}

@font-face {
  font-family: Avenir;
  src: url("../../fonts/Gotham-Font/AvenirLTStd-Roman.otf");
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/Proxima_Nova_Alt_Bold.otf");
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/Proxima_Nova_Alt_Light.otf");
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/Proxima_Nova_Alt_Thin.otf");
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/Proxima_Nova_Black.otf");
  font-display: swap;
  font-weight: 900;
}
@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/Proxima_Nova_Semibold.otf");
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/Proxima_Nova_Bold.otf");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/Proxima_Nova_Extrabold.otf");
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/Proxima_Nova_Thin.otf");
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova;
  src: url("../../fonts/ProximaNova/ProximaNova-Regular.otf");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Perpetua;
  src: url("../../fonts/Perpetua/per_____.ttf");
  font-display: swap;
}

@font-face {
  font-family: Perpetua;
  src: url("../../fonts/Perpetua/perb____.ttf");
  font-display: swap;
}

@font-face {
  font-family: Perpetua;
  src: url("../../fonts/Perpetua/perpeta.ttf");
  font-display: swap;
}

@font-face {
  font-family: Perpetua;
  src: url("../../fonts/Perpetua/Perpetua\ Bold\ font.ttf");
  font-display: swap;
}

@font-face {
  font-family: Perpetua;
  src: url("../../fonts/Perpetua/unicode.perpetua.ttf");
  font-display: swap;
}
@font-face {
  font-family: satisfy;
  src: url("../../fonts/satisfy-regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: holidayfree;
  src: url("../../fonts/holidayfree.otf");
  font-display: swap;
}
