@import "../abstracts/_abstracts";
.buyBtn {
  background: linear-gradient(91.1deg, #cda774 -8.1%, #ab7c62 124.87%);
  box-shadow: 2px 8px 15px rgba(20, 20, 20, 0.17);
  // border-radius: 8px !important;
  border: inherit;
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.addCBtn {
  background: #ffffff;
  border: 1px solid #b28566;
  border-radius: 6px;
  color: #b28566;
  font-size: 20px;
  font-weight: 500;
}
#Product {
  .bg-lightGrey {
    background-color: $lightGrey;
  }

  .price_text {
    font-weight: 700;
    font-size: 1.25rem;

    .rupee-symbol{
      color: #272727;
      font-family: Arial, Helvetica, sans-serif;
    }

    span {
      color: $grey-b8;
    }
  }

  #slide {
    .slick-track {
      margin-bottom: 30px;
    }

    .slick-dots {
      margin-top: 50px;

      li {
        height: 65px;
        width: 70px;
      }
    }
  }

  .text-minus {
    font-size: 1rem;

    .quantityBox {
      border: 1px solid #b8b8b8;
      border-radius: 2px;
      display: flex;
      justify-content: center;
    }
  }

  span.text-grey-a8 {
    font-size: 1.125rem;
  }

  .tax_text {
    font-size: 1.125rem;
    color: $grey-65;
  }

  .dropdown {
    .nav-link {
      display: inline;
      &::after {
        display: none;
        content: none !important;
      }
    }
  }

  .detail {
    left: -7%;

    .form-check-input {
      transform: scale(2.5);
    }

    h5 {
      font-size: 2rem;
      font-family: Perpetua;
      color: $black-07;
    }

    .cateName {
      font-size: 1.15rem !important;
      font-family: Proxima Nova;
      color: $secondary-color;
    }

    .form-check-inline {
      &:first-child {
        input[type="radio"]:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: $black-19;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 1px solid $grey-86;
        }

        input[type="radio"]:checked:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: $black-19;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 1px solid $grey-86;
          box-shadow: 0.1px 0.1px 0.1px 0.5px $grey-86;
        }
      }

      &:nth-child(2) {
        input[type="radio"]:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: white;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 1px solid $grey-86;
        }

        input[type="radio"]:checked:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: white;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 1px solid $grey-86;
          box-shadow: 0.1px 0.1px 0.1px 0.5px $grey-86;
        }
      }

      &:nth-child(3) {
        input[type="radio"]:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: $red-e3;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 1px solid $grey-86;
        }

        input[type="radio"]:checked:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: $red-e3;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 1px solid $grey-86;
          box-shadow: 0.1px 0.1px 0.1px 0.5px $grey-86;
        }
      }
    }
  }

  .stickeyHeader {
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    background: white;
    .shadowDiv {
      box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
    }
  }

  #aboutChef {
    h1 {
      font-size: 2.5rem;
      letter-spacing: -0.06em;
      font-weight: 700;
      text-decoration: underline;
      text-decoration-color: $secondary-color;
      line-height: 58px;
      text-underline-position: below;
    }
  }

  #productModal {
    .bg-gradient {
      background: linear-gradient(
        91.1deg,
        rgba(205, 167, 116, 0.9) -8.1%,
        #ab7c62 124.87%
      );
      opacity: 0.95;
    }
  }

  #prdImg {
    img.m-auto.img-responsive {
      height: 250px;
    }
  }

  @media only screen and (max-width: 600px) {
    .w-90 {
      width: 95% !important;
    }

    #prdImg {
      img.m-auto.img-responsive {
        width: 370px;
        height: 340px;
      }
    }

    .detail {
      left: auto;

      .form-check-input {
        transform: scale(2.5);
      }

      h5 {
        font-size: 1.5rem;
      }

      .cateName {
        font-size: 1rem !important;
      }

      .form-check-inline {
        &:first-child {
          input[type="radio"]:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: $black-19;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 1px solid $grey-86;
          }

          input[type="radio"]:checked:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: $black-19;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 1px solid $grey-86;
            box-shadow: 0.1px 0.1px 0.1px 0.5px $grey-86;
          }
        }

        &:nth-child(2) {
          input[type="radio"]:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: white;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 1px solid $grey-86;
          }

          input[type="radio"]:checked:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: white;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 1px solid $grey-86;
            box-shadow: 0.1px 0.1px 0.1px 0.5px $grey-86;
          }
        }

        &:nth-child(3) {
          input[type="radio"]:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: $red-e3;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 1px solid $grey-86;
          }

          input[type="radio"]:checked:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: $red-e3;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 1px solid $grey-86;
            box-shadow: 0.1px 0.1px 0.1px 0.5px $grey-86;
          }
        }
      }
    }
    #aboutChef {
      h1 {
        font-size: 1.5rem;
        letter-spacing: -0.06em;
        font-weight: 700;
        text-decoration: underline;
        text-decoration-color: red;
        line-height: 58px;
        text-underline-position: below;
      }
    }

    .tax_text {
      padding: 0;
      margin: 0;
    }

    .buyBtn {
      background: linear-gradient(91.1deg, #cda774 -8.1%, #ab7c62 124.87%);
      box-shadow: 2px 8px 15px rgba(20, 20, 20, 0.17);
      // border-radius: 8px !important;
      border: inherit;
    }

    .addCBtn {
      background: #ffffff;
      border: 1px solid #b28566;
      border-radius: 6px;
      color: #b28566;
    }
  }
}
