@import "../abstracts/abstracts";

#Login,
#Signup,
#ForgotPwd {
  // height: 100vh;

  .d-none {
    p {
      font-size: 1rem;
    }
  }
  .loginPwdCntr {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .backArrow {
    cursor: pointer;
    position: relative;
    width: fit-content;
    z-index: 2;
    svg {
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 1rem;
      left: 1rem;
      color: #eeeae6;
      background-color: #263238;
      border-radius: 2rem;
      padding: 5px;
    }
  }

  .backArrow.signup {
    svg {
      top: -2rem;
    }
  }

  label {
    color: $grey-69;
    font-weight: 500;
    font-family: sans-serif;
  }

  .slide {
    background-color: $grey-ee;

    img {
      width: 625px;
      margin: 0 auto;
    }

    .slick-dots li button {
      width: 30px;
      height: 30px;
      padding: 5px;
      text-shadow: 2px 2px #818181;
    }

    .slick-dots li.slick-active button {
      &:before {
        color: white;
      }
    }

    .slick-dots li button {
      &:before {
        font-size: 10px;
        color: inherit;
      }
    }

    .slick-arrow {
      display: none !important;
    }

    .logo {
      width: 178px;
    }
  }

  .formData {
    height: 100%;

    .border-bottom {
      border-bottom: 2px solid $grey-e3 !important;
    }

    button {
      height: 4rem;
      font-family: Proxima Nova !important;
      border-radius: 8px !important;
      border-color: $grey-86 !important;
    }
    .input-group {
      position: relative;
      // z-index: -1;
      .input-group-append {
        position: absolute;
        top: 25%;
        right: 21px;
        z-index: 999;
        // z-index: 2;
      }
      .form-control {
        width: 100%;
        height: 50px;
        border-radius: 4px;

        &:focus {
          border: unset !important;
          border-right: none !important;
        }
      }
    }
  }
}
